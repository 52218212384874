import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import './css/main.scss';
import logo_spend from './images/logo_spend.svg';
import logo from './images/logo_white_transparent.png';

export const App = () => {
  return (
    <Container fluid className={'theme3'}>
      <Row className={'header'}>
        <Col>
          <Container>
            <Row>
              <Col md={12} className={'description'}>
                <div className={'logo'}>
                  <img src={logo} className={'img-responsive'} alt={''}/>
                </div>
                <h1>Competitive Intelligence in the Public Procurement Market</h1>
                <p className={'lead'}>State of the art business advisory & consultancy services for monitoring, winning
                  and/or
                  awarding Public Procurement contracts. We are proud to serve a broad range of stakeholders from various
                  national & international organizations, civil society, media, to central/local contracting entities,
                  regulating/monitoring/control bodies of the State, and publicly or privately held companies.</p>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className={'even'}>
        <Col>
          <Container>
            <Row>
              <Col md={12}>
                <div className={'text-center'}>
                  <Button tag={'a'} href={'mailto:sales@xspend.ro'} size={'lg'} color={'success'}>Expand your performance <i
                    className="fa fa-arrow-right"></i> Contact Us</Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className={'odd'}>
        <Col>
          <Container>
            <Row>
              <Col md={12}>
                <div className="page-header">
                  <h1>The Problem</h1>
                </div>
                <p className="lead">Identifying and comparing relevant data &amp; information extracted from the Electronic
                  System for Public Procurement (SEAP) without innovative tools specifically tailored for competitive
                  intelligence in the Public Procurement market may result in a burdensome, time consuming and costly process
                  due to the volume of data on the evolution, scale and dynamics of this sector.</p>
              </Col>
              <Col md={6}>
                <ul>
                  <li>Public Procurement stakeholders (registered with SEAP): over 22,000 contracting entities and more than
                    200,000 contractors
                  </li>
                  <li>Big data: over 30,000,000 procurement procedures/contracts</li>
                </ul>
              </Col>
              <Col md={6}>
                <ul>
                  <li>Average of 10,500 awards/day
                  </li>
                  <li>More than 7,500 Common Procurement Vocabulary codes used, with millions of products
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className={'even'}>
        <Col>
          <Container>
            <Row>
              <Col md={12}>
                <div className="page-header"><h1>The Solution</h1></div>
                <p className="lead">
                  <a href="https://www.spend.ro" target="_blank" rel={'noreferrer noopener'}>
                    <img src={logo_spend} className="" alt={''} height={30}/>
                  </a> is an unique
                  competitive intelligence software platform for the Public Procurement market in Romania with value added
                  functionalities that:</p>
              </Col>
              <Col md={6}>
                <ul>
                  <li>Builds-up administrative, organizational and strategic planning capacity of all types of Public
                    Procurement stakeholders
                  </li>
                  <li>Allow processing and interpretation of Raw Public Procurement data</li>
                  <li>Cleans, compiles and then reutilizes the public available data by deploying proprietary innovative IT
                    algorithms to provide real-time competitive analytics
                  </li>
                  <li>Deliver structured results, finite informational formats (synthetic / detailed / graphic) and solid
                    insights of the information that matters
                  </li>
                  <li>Increasing the accuracy of the results due to the high amount of available data which enables adeqaute
                    sampling and projection
                  </li>
                </ul>
              </Col>
              <Col md={6}>
                <ul>
                  <li>Optimize the decision making by backing them with real-time data and early warning filtered
                    highlights
                  </li>
                  <li>Reveal the economic value of Public Procurement aggregated data by capturing the size and trends for
                    different market segments, their geographical distribution and key players
                  </li>
                  <li>Maximize the outputs of the resources that are involved in market research, specific Public Procurement
                    analysis and case studies by significantly reducing time and costs to delivers such tasks
                  </li>
                  <li>Expand the level of Public Procurement performance by endorsing the principle of promoting competition,
                    thus contributing to awarding contracts in a fair manner, and consistent with efficent management of
                    available funds
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className={'odd'}>
        <Col>
          <Container>
            <Row>
              <Col md={12}>
                <div className="page-header"><h1>About Us</h1></div>
                <p className="lead">We are a privately held company based in Bucharest, Romania, incorporated in 2014,
                  operating
                  in the field of competitive intelligence, consultancy services and custom developed software components
                  specifically tailored for Public Procurement. X-Spend’s focus is set on leveraging unique data assets,
                  business advisory &amp; engagement for fuelling competitive advantage necessary to tackle opportunities and
                  challenges of the Public Procurement sector. X-Spend consulting offers to its clients real-time access to
                  all the relevant data on public procurement contracts awarded since 2007. We promote the use of an unique
                  innovative software platform - Spend.ro, which offers unprecedented power of computation, easy to grasp and
                  simplified views of the market. We offer to our business partners and clients real-time access to all the
                  relevant data on Public Procurement contracts awarded in Romania since 2007 by promoting the use of an
                  unique innovative software platform - Spend.ro. Our platform delivers an unprecedented power of
                  computation, easy to grasp and simplified views of the market, addressing equally the needs of: various
                  national &amp; international organizations, central/local contracting authorities/entities, the States’
                  regulation/monitoring and/or control Bodies, civil society, media, and publicly or privately held companies
                  that are active on this market.</p>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className={'even'}>
        <Col>
          <Container>
            <Row>
              <Col md={12}>
                <footer>
                  <p>X-Spend Consulting SRL Silozului no. 19 Street, 2nd floor, Office 5, Sector 2, Bucharest Romania,
                    Email: <a href="mailto:sales@xspend.ro">sales@xspend.ro</a>
                  </p>
                  <p> © Copyright 2023</p>
                </footer>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};
